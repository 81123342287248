import React from "react"
import AdSlot from "react-google-publisher-tag"

const Ad = ({unit}) => {
  const AdUnitID = "9302405839"

  return (
    <div className="ad-container">
      <AdSlot
        adUnit={unit || AdUnitID}
        sizes={[
          [300, 250],
          [336, 280],
        ]}
      />
    </div>
  )
}

export default Ad
