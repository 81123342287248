import React from "react";
import Jumbo from "../components/Sections/Jumbo";
import app from "../App.json";
import StoreButton from "../components/Buttons/StoreButtons";
import Footer from "../components/Sections/Footer";

const Home = () => {
  const storeButton = <StoreButton />;

  return (
    <div>
      {/* Hero Section */}
      <Jumbo
        title={app.name}
        body="Discover a new way to express yourself, tell a story or share an idea and connect with the audio space."
        button={storeButton}
      />

      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default Home;