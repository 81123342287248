// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box{
    height: 100vh !important;
    color: #fff;
    padding-top: 150px !important;
    background-size: cover !important;
    background-repeat:no-repeat !important;
    background-position:center !important;
  }


 .box h1{
     font-size: 60px !important;
     margin-bottom: 0px !important;
}

.border-curve{
    border-radius: 16px !important;
}


.tint{
    background-color: rgba(0, 0, 0, 0.514) !important;
    background-blend-mode: soft-light !important;
    background-size: cover !important;
    background-position:center !important;
}`, "",{"version":3,"sources":["webpack://./src/css/Jumbo.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,WAAW;IACX,6BAA6B;IAC7B,iCAAiC;IACjC,sCAAsC;IACtC,qCAAqC;EACvC;;;CAGD;KACI,0BAA0B;KAC1B,6BAA6B;AAClC;;AAEA;IACI,8BAA8B;AAClC;;;AAGA;IACI,iDAAiD;IACjD,4CAA4C;IAC5C,iCAAiC;IACjC,qCAAqC;AACzC","sourcesContent":[".box{\n    height: 100vh !important;\n    color: #fff;\n    padding-top: 150px !important;\n    background-size: cover !important;\n    background-repeat:no-repeat !important;\n    background-position:center !important;\n  }\n\n\n .box h1{\n     font-size: 60px !important;\n     margin-bottom: 0px !important;\n}\n\n.border-curve{\n    border-radius: 16px !important;\n}\n\n\n.tint{\n    background-color: rgba(0, 0, 0, 0.514) !important;\n    background-blend-mode: soft-light !important;\n    background-size: cover !important;\n    background-position:center !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
