import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import "../css/Conversation.css"
import {API, REMOTE_FILES_BASE_URL} from "../API"
import {ClipLoader} from "react-spinners"
import {Helmet} from "react-helmet"
import Lottie from "react-lottie"

import animationData from "../animation.json"
import Reply from "../components/Casts/reply"
import Cast from "../components/Casts"
import StoreButton from "../components/Buttons/StoreButtons"
import Ad from "../components/Ads"
import FootAd from "../components/Ads/foot-ad"
import Footer from "../components/Sections/Footer"

const Conversation = () => {
  const {castID} = useParams() // Get the castID from the URL
  const [mainPost, setConversationData] = useState(null)
  const [repliesData, setRepliesData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const fetchConversation = async () => {
    try {
      setLoading(true)
      const response = await API.get(`/casts/${castID}/public`)
      console.log(response.data.data)
      setConversationData(response.data.data)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  const fetchReplies = async () => {
    try {
      const response = await API.get(`/casts/${castID}/replies/public`)
      setRepliesData(response.data.data)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    window.googletag = window.googletag || {cmd: []}
    window.googletag.cmd.push(() => {
      window.googletag.pubads().enableSingleRequest()
      window.googletag.enableServices()
    })
  }, [])
  useEffect(() => {
    fetchConversation()
    fetchReplies()
  }, [castID])

  useEffect(() => {
    if (mainPost) {
      document.title = `${mainPost.caster?.full_name} - ${mainPost.title} | JustTalk`
    }
  }, [mainPost])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  if (loading)
    return (
      <div className="message-container">
        <ClipLoader color="#36D7B7" size={50} />
        <p className="loading-message">Loading conversation...</p>
      </div>
    )
  if (error) {
    return (
      <div className="message-container">
        <Lottie
          options={defaultOptions} // Ensure it falls back properly
          autoPlay
          loop={false}
          width={150}
          height={150}
        />
        <p className="error-message">Error: {error}</p>
        <button
          onClick={() => {
            fetchConversation()
          }}
          className="download-button ios"
        >
          Try again
        </button>
      </div>
    )
  }
  if (!mainPost) {
    return (
      <>
      <div className="message-container">
        <Lottie
          options={defaultOptions} // Ensure it falls back properly
          autoPlay
          loop={false}
          width={150}
          height={150}
        />
        <p className="no-conversation-message">No conversation found.</p>
      </div>
      <Footer />
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>{mainPost?.title || "JustTalk"}</title>
        <meta property="og:title" content={mainPost?.caster?.full_name} />
        <meta
          property="og:description"
          content={mainPost?.title || "Join the conversation on JustTalk"}
        />
        <meta
          property="og:image"
          content={REMOTE_FILES_BASE_URL + "/" + mainPost?.caster?.photo}
        />

        {/* Ensure og:image is always set */}
        {mainPost?.caster?.photo ? (
          <meta
            property="og:image"
            content={`${REMOTE_FILES_BASE_URL}/${mainPost?.caster?.photo}`}
          />
        ) : (
          <meta
            property="og:image"
            content={`${REMOTE_FILES_BASE_URL}/default.png`}
          />
        )}
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:title" content={mainPost?.caster?.full_name} />
        <meta
          name="twitter:description"
          content={mainPost?.title || "Listen to this cast on JustTalk"}
        />
        {/* Ensure og:image is always set */}
        {mainPost?.caster?.photo ? (
          <meta
            property="twitter:image"
            content={`${REMOTE_FILES_BASE_URL}/${mainPost?.caster?.photo}`}
          />
        ) : (
          <meta
            property="twitter:image"
            content={`${REMOTE_FILES_BASE_URL}/default-thumbnail.jpg`}
          />
        )}
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="conversation">
        <Cast cast={mainPost} />
        <Ad />
        <h2 className="replies-title">Replies ({mainPost.no_replies})</h2>
        <div className="replies">
          {repliesData.length === 0 ? (
            <div className="no-replies">
              <p className="no-replies-message">
                No replies yet. Be the first to respond!
              </p>
            </div>
          ) : (
            repliesData.map((reply, index) => (
              <React.Fragment key={index}>
                <Reply cast={reply} />
                 
                {/* {index % 3 === 2 && <Ad />} */}
              </React.Fragment>
            ))
          )}
        </div>
        <StoreButton />
        <FootAd />
      </div>
      <Footer />
    </>
  )
}

export default Conversation
