/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {Container} from "react-bootstrap"
import Lottie from "react-lottie"
import "../../css/Jumbo.css"
import animationData from "../../waves.json"
import {motion, useAnimation} from "framer-motion"

const Jumbo = ({body = "", title, image, button}) => {
  const words = typeof body === "string" ? body.split(" ") : []
  const controls = useAnimation()

  useEffect(() => {
    const animateWords = async () => {
      for (let i = 0; i < words.length; i++) {
        await controls.start({
          opacity: 1,
          transition: {duration: 10, delay: i * 5},
        })
      }
    }
    animateWords()
  }, [words, controls])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <Container
      fluid
      className="box text-center h-full gradient-bottom-top"
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
    >
      <h1 className="text-effect" style={{fontSize: 70, fontWeight: "bolder"}}>
        {title}
      </h1>

      <p
        style={{
          fontSize: 30,
          width: "100%",
          margin: "10px auto",
          lineHeight: "1.5",
        }}
        className="description-container"
      >
        {words.map((word, index) => (
          <motion.span
            key={index}
            initial={{opacity: 0}}
            animate={controls}
            className="description-word"
          >
            {word}{" "}
          </motion.span>
        ))}
      </p>

      {button && <div style={{marginTop: "20px"}}>{button}</div>}

      <div style={{marginTop: "40px", marginTop: "10%"}}>
        <Lottie
          options={defaultOptions}
          height={200}
          width={"100%"}
          autoPlay
          speed={0.4}
          loop={true}
        />
      </div>
    </Container>
  )
}

export default Jumbo
