
import "../../css/Conversation.css"
import {REMOTE_FILES_BASE_URL} from "../../API"
import moment from "moment"
import CastAudioPlayer from "../CastAudioPlayer"
import React, {useEffect, useRef} from "react"

const Cast = ({cast}) => {
  const audioRef = useRef(new Audio(REMOTE_FILES_BASE_URL + "/" + cast.cast));
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    const audio = audioRef.current;

    const updateProgress = () => {
      const progressPercent = (audio.currentTime / audio.duration) * 100;
      setProgress(progressPercent);
    };

    audio.addEventListener("timeupdate", updateProgress);

    return () => {
      audio.removeEventListener("timeupdate", updateProgress);
    };
  }, []);
  return (
    <div className="post">
      <div className="post-header">
        <img
          src={REMOTE_FILES_BASE_URL + "/" + cast.caster?.photo}
          alt="Profile"
          className="profile-image"
        />
        <div className="post-user-info">
          <b className="font-[18px]">{cast.caster?.full_name}</b>{" "}
          <span className="text-grey">@{cast.caster?.user_name}</span>
          <p className="post-content">{cast.title}</p>
        </div>

        <CastAudioPlayer cast={cast} />
      </div>

      <div className="post-footer">
        <span>
          {moment(cast.createdAt).fromNow()} &nbsp; &middot; &nbsp;
          <b>{cast.no_listeners}</b> Listened
        </span>{" "}
      </div>

       {/* Progress Bar */}
       {/* <div className="progress-container">
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      </div> */}
    </div>
  )
}

export default Cast
