import React from "react"

const FootAd = ({unit}) => {
  const AdUnitID = "7606180785"

  return (
    <div className="ad-container">
      <amp-ad
        width="100vw"
        height="320"
        type="adsense"
        data-ad-client="ca-pub-8004122002631398"
        data-ad-slot={unit || AdUnitID}
        data-auto-format="rspv"
        data-full-width=""
      >
        <div overflow=""></div>
      </amp-ad>
    </div>
  )
}

export default FootAd
