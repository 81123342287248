import React, { useRef, useState, useEffect } from "react";
import "react-h5-audio-player/lib/styles.css";
import { FaPlay, FaPause } from "react-icons/fa";
import { REMOTE_FILES_BASE_URL, API } from "../API";
import "../css/Conversation.css";

const CastAudioPlayer = ({ cast }) => {
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [hasPlayed, setHasPlayed] = useState(false);
  const audioRef = useRef(new Audio(REMOTE_FILES_BASE_URL + "/" + cast.cast));

  useEffect(() => {
    const playedCasts = JSON.parse(localStorage.getItem("playedCasts")) || [];
    if (playedCasts.includes(cast._id)) {
      setHasPlayed(true);
    }
  }, [cast._id]);

  useEffect(() => {
    const audio = audioRef.current;

    const updateProgress = () => {
      const progressPercent = (audio.currentTime / audio.duration) * 100;
      setProgress(progressPercent);
    };

    audio.addEventListener("timeupdate", updateProgress);

    return () => {
      audio.removeEventListener("timeupdate", updateProgress);
    };
  }, []);

  const playPause = async () => {
    if (playing) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();

      if (!hasPlayed) {
        try {
          await API.patch(`/casts/${cast._id}/listen`, {
            cast_id: cast._id
          });
          const playedCasts = JSON.parse(localStorage.getItem("playedCasts")) || [];
          playedCasts.push(cast._id);
          localStorage.setItem("playedCasts", JSON.stringify(playedCasts));
          setHasPlayed(true);
        } catch (error) {
          console.error("Error updating listen count:", error);
        }
      }
    }
    setPlaying(!playing);
  };
  return (
    <>
      <div className="play-section">
        {playing ? (
          <div className="pause-button" onClick={playPause}>
            <FaPause color="green" size={20} className="green" />
          </div>
        ) : (
          <div className="play-button" onClick={playPause}>
            <FaPlay color="green" size={25} className="green" />
          </div>
        )}
        <span className="duration">{cast.duration}s</span>
      </div>
    </>
  );
};

export default CastAudioPlayer;
