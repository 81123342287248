import React from "react"
import "../../css/Conversation.css"
import {REMOTE_FILES_BASE_URL} from "../../API"
import moment from "moment"
import CastAudioPlayer from "../CastAudioPlayer"

const Reply = ({cast}) => {
  return (
    <div className="reply">
    <div className="reply-header">
      <img
        src={REMOTE_FILES_BASE_URL + "/" + cast?.caster?.photo}
        alt="Profile"
        className="profile-image"
      />
      <div className="reply-user-info">
        <b>{cast.caster?.full_name}</b>{" "}
        <span className="text-grey">
          @{cast.caster?.user_name}
        </span>
        <p className="post-content">
          {moment(cast.createdAt).fromNow()}
        </p>
      </div>
      <CastAudioPlayer cast={cast} />
    </div>
  </div>
  )
}

export default Reply
