import React from 'react';
import "../../css/Buttons.css";
import json from "../../Config";
import {FaApple, FaGooglePlay} from "react-icons/fa"


class StoreButton extends React.Component{
  constructor(props) {
    super();
  this.state = json;

  }

  render(){

return (
      <>
      <div className="download-buttons">
        <a href={this.state.appstore} target="_blank" rel="noopener noreferrer" className="download-button ios">
          <FaApple /> Download on iOS
        </a>
        <a href={this.state.playstore} target="_blank" rel="noopener noreferrer" className="download-button android">
          <FaGooglePlay /> Download on Android
        </a>
      </div>
      </>
);

  }

};

export default StoreButton;
